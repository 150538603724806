.intro-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
}

.logo {
  width: 25px;
  height: 25px;
}

.logo-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Main Content */
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1; 
}

.intro-heading {
  font-size: 3rem;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.intro-subheading {
  font-size: 1.5rem;
  margin-bottom: 1.5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.intro-subheading2 {
  font-size: 1.5rem;
  margin-bottom: 70px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.intro-buttons {
  display: flex;
  gap: 40px;
  padding-top: 10px;
}

.intro-button {
  padding: 10px 20px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.intro-button:hover {
  background-color: white;
  color: black;
}

/* View Resume Section */
.resume-view {
  margin-top: 40px;
}

.view-link {
  color: white;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.view-link:hover {
  color: gray;
}

.view-icon {
  width: 14px;
  height: 14px;
}

.jira-link {
  position: absolute;
  top: 55px;
  right: 50px;
  font-size: 14px;
  text-decoration: none;
  color: #ccc;
  font-weight: 800;
}

.jira-link:hover {
  color: #fff;
  text-decoration: underline;
}
