.friend-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
    padding: 20px 0;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .logo {
    width: 30px;
    height: 30px;
  }
  
  .logo-text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    margin-top: 5px;
  }
  
  /* Main content before a question is selected */
  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .subheading {
    font-size: 1.5rem;
    margin-bottom: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 550;
  }
  
  .question-buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .question-button {
    padding: 10px 20px;
    border: 2px solid white;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .question-button:hover {
    background-color: white;
    color: black;
  }
  
  /* Custom free text input field */
  .custom-question {
    width: 300px;
    height: 20px;
    background-color: transparent;
    color: rgb(150, 150, 150);
    border: 2px solid white;
    margin-top: 20px;
    padding: 10px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    z-index: 1;
    position: relative;
  }
  
  /* If a question is selected, fix buttons to the top */
  .fixed-buttons {
    position: sticky;
    padding-top: 30px;
    top: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    z-index: 100;
    background-color: black;
  }
  
  /* Center the typewriter text vertically and horizontally */
  .center-content {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start; /* Align text to start, so it has space from the top */
        padding: 100px 20px 0; /* Add padding to the top to prevent the text from overlapping with the buttons */
        max-width: 80%;
        white-space: pre-wrap;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        overflow-y: auto; /* Allow vertical scrolling if text is too long */
      }
  
  .typewriter-text {
    font-size: 2.5rem;
    line-height: 1.6;
    max-width: 80%;
    font-size: 25px;
    white-space: pre-wrap; /* Preserve newlines */
    font-family: 'Roboto Mono', monospace;
    white-space: pre-wrap; /* Ensures newline characters (\n) are rendered */
      }

  .loading-text {
    color: rgb(175, 174, 174);
    font-size: 1.5rem;
    margin-top: 20px;
      }
    